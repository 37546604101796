<template>
  <div class="container-delete">
    <div class="main-title">
      <h1>Delete Position <span><i class="far fa-exclamation-circle" style="color: #e96464"></i></span></h1>
    </div>
    <div class="content-card-layout">
      <div class="main-content-card">
        <h4 class="ml-2 mb-10" style="font-weight: 400;color:red;">Do you want to delete ?</h4>
      </div>
    </div>
    <div class="section-footer-delete">
      <div class="btn-section">
        <v-btn class="btn-cancel" outlined @click="$emit('close')">
          cancel
        </v-btn>
        <v-btn class="btn-action cancel" @click="DeleteItem(positionId)">
          Delete
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    positionId: {},
  },
  data() {
    return {
      server_errors: {
        id: ""
      }
    }
  },
  methods: {
    DeleteItem(positionId) {
      this.$axios.delete(`company/position/${positionId}`).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'danger', 3000);
          }, 300);
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          let obj = error.response.data.errors;
          if (!obj) {
            obj = error.response.data;
            let res = "";
            for (let [key, value] of Object.entries(obj)) {
              res = value;
            }
            this.$notification.ShowErrors("top-right", "danger", 3000, res);
          } else {
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
              this.$notification.ShowErrors(
                "top-right",
                "danger",
                3000,
                (this.server_errors[key] = value[0])
              );
            }
          }
        }
        if (error.response.status === 500) {
          this.$notification.ShowErrors(
            "top-right",
            "danger",
            3000,
            "server error"
          );
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.container-delete {
  width: 100%;
  height: 180px;

  .main-title h1 {
    font-size: 26px;
    font-weight: bold;
    margin-left: 30px;
  }
}
</style>
