<template>
  <div class="main-content">
    <div class="card-layout">
      <div class="from-content">
        <div class="from">
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                    dense
                    outlined
                    v-model="position.name"
                    :label="$t('position.name')"
                    :hint="`${server_errors.name}`"
                    persistent-hint
                >
                </v-text-field>
              </v-col>
<!--              <v-col cols="12" md="12">-->
<!--                <v-text-field-->
<!--                    class="input-number"-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="position.salary"-->
<!--                    :label="$t('position.salary')"-->
<!--                    type="number"-->
<!--                    :hint="`${server_errors.salary}`"-->
<!--                    persistent-hint-->
<!--                >-->
<!--                </v-text-field>-->
<!--              </v-col>-->
<!--              <v-col cols="12" md="12">-->
<!--                <v-select-->
<!--                    dense-->
<!--                    outlined-->
<!--                    :items="listCurrency"-->
<!--                    item-text="name"-->
<!--                    item-value="id"-->
<!--                    v-model="position.currency_id"-->
<!--                    :label="$t('position.currency')"-->
<!--                    :hint="`${server_errors.currency_id}`"-->
<!--                    persistent-hint-->
<!--                >-->
<!--                </v-select>-->
<!--              </v-col>-->
              <v-col cols="8">
                <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
                  {{ $t("position.update") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    position: {},
  },
  data() {
    return {
      btnLoading: false,
      // listCurrency: [],
      server_errors: {
        name: "",
        // salary: "",
        // currency_id: ""
      }
    }
  },
  methods: {
    fetchCurrency() {
      this.$axios.get(`company/currency`).then((res) => {
        if (res.data.code === 200) {
          this.listCurrency = res.data.data;
        }
      }).catch(() => {
      })
    },
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.UpdateItem();
        this.btnLoading = true;
      }
    },

    resetForm() {
      this.$refs.form.reset()
    },

    UpdateItem() {
      this.$axios.put(`company/position/${this.position.id}`, this.position).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          setTimeout(() => {
            this.resetForm()
          }, 300)
          this.btnLoading = false;
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
  created() {
    this.fetchCurrency()
  }
}
</script>
<style scoped lang="scss">
.main-content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .card-layout {
    width: 400px;
    height: 500px;
  }
}
</style>
