var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"section-topic"},[_c('div',{staticClass:"title-header"},[_c('h1',[_vm._v(_vm._s(_vm.$t("position.title")))])])]),_c('div',{staticClass:"section-action"},[_c('div',{staticClass:"search-action"},[_c('v-text-field',{staticClass:"btn btn-color btn-border btn-border-color",attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","placeholder":_vm.$t('Search.search')},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchFilterItem.apply(null, arguments)}},model:{value:(_vm.searchItem),callback:function ($$v) {_vm.searchItem=$$v},expression:"searchItem"}})],1),_c('div',{staticClass:"btn-new-create-action"},[_c('v-btn',{staticClass:"btn-create",on:{"click":function($event){return _vm.onCreate()}}},[_vm._v(" "+_vm._s(_vm.$t("position.create"))+" ")])],1)]),_c('div',{staticClass:"section-form-content"},[_c('div',{staticClass:"block-content"},[(_vm.listPosition.length > 0)?_c('table',{staticClass:"table table-view"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("position.no")))]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("position.name"))+" ")]),_c('th',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.$t("position.addEmployee"))+" ")])])]),_c('tbody',_vm._l((_vm.listPosition),function(position,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm.pagination.current_page * 10 - 10 + index + 1))]),_c('td',[_vm._v(" "+_vm._s(position.name)+" ")]),_c('td',{staticClass:"text-end"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('vs-tooltip',{ref:"addTooltip",refInFor:true,staticStyle:{"float":"right"},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Add Employee ")]},proxy:true}],null,true)},[_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.EmployeePositionIndex(position.id)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-briefcase",staticStyle:{"font-size":"18px","color":"#878787"}})])],1)]}}],null,true)})],1),_c('td',{staticClass:"text-end"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-ellipsis-v btn-ellipsis"})])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"btn-edit",on:{"click":function($event){return _vm.onEdit(position.id)}}},[_vm._v("Edit")])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"btn-delete",on:{"click":function($event){return _vm.onDeleted(position.id)}}},[_vm._v("Delete")])],1)],1)],1)],1)])}),0)]):_c('default-table-no-result'),(_vm.pagination.total_pages > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":_vm.fetchPosition}}):_vm._e(),_c('ModalEdit',{attrs:{"fullscreen":"","title":"Edit Position"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('EditPosition',{attrs:{"position":_vm.position},on:{"close":close,"success":function($event){return _vm.fetchPosition()}}})]}}])}),_c('ModalDelete',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('DeletePosition',{attrs:{"positionId":_vm.positionId},on:{"close":close,"success":function($event){return _vm.fetchPosition()}}})]}}])}),(_vm.isLoading)?_c('Loading'):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }